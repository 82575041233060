<template>
  <div class="page-container examination-apply-form">
    <div>
      <el-steps align-center :active="stepState" finish-status="success">
        <!-- <el-step icon="el-icon-edit" title="填写信息" ></el-step>
        <el-step icon="el-icon-upload" title="上传文件" ></el-step> -->
        <el-step title="填写考生信息" ></el-step>
        <el-step title="上传一寸照片" ></el-step>
      </el-steps>
    </div>
    <div v-if="stepState===0">
       <FormGenerator 
          ref="forms"
          :defaultValue="formValues"
          :formOption="formOption"
          @submit="saveFormValue"
        />
        <div>
          <el-button type="primary" size="mini" @click="formBtn">下一步</el-button>
        </div>
    </div>
    <div v-if="stepState===1" class="photo-container">

        <div class="photo">
          <el-image
            @click="uploadAvatar"
            class="photo-img"
            :src="formValues.image_url"
            fit="cover"
          >
          <div slot="error" class="image-slot" style="margin-top:50px">
            <i class="el-icon-picture-outline" style="width:120px;font-size:40px;color:#999;"></i>
          </div>
          </el-image>
          <el-button  type="primary" size="mini" :loading="uploadLoading" @click="uploadAvatar">上传一寸照片</el-button>
        </div>

        <input
          type="file"
          style="display: none"
          id="avatar"
          ref="avatar"
          @change="beforeUpload"
        />

        <div style="margin:20px 0;text-align:center;padding:20px">
          <el-button type="primary" style="width:200px" size="mini" :disabled="!(this.valid&&this.formValues.image_url)" @click="submitApply">提交</el-button>
        </div>

    </div>
   
    
  </div>
</template>
<script>
import FormGenerator from "components/formGenerator/formGenerator"
import { resumeData, politicsStatus,/*category,city, wxCity*/  } from "constants/publicData";
import {mapState} from "vuex"
export default {
  name:"ExaminationApplyForm",
  model:{
    props:"value",//props的默认值
    event:"change"//触发的事件
  },
  props:[],
  data:()=>({
    stepState:0,
    formValues:{
      UCVA:{},
      CVA:{},
      full_time:{},
      ceiling:{},
      my_portfolio:[{}],
      primary_relationship:[{}]
    },
    formOption: {
      labelPosition: "top",
      formClass: "lable-position-top",
      formName: "applyForm",
      noButton:true,
      items: [
        {
          type: "input",
          label: "姓名",
          key: "name",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "radio",
          label: "性别",
          key: "sex",
          options: ["男", "女"],
          rules: [{ required: true, message: "请选择" }],
          style:'width:45%;'
        },
        {
          type: "input",
          label: "民族",
          key: "national",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "radio",
          label: "婚否",
          key: "marriage",
          options: ["是", "否"],
          rules: [{ required: true, message: "请选择" }],
          style:'width:45%;'
        },
        {
          type: "idCard",
          label: "身份证号",
          key: "id_card",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "phone",
          label: "联系电话",
          key: "phone_number",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "input",
          label: "籍贯",
          key: "ji_guan",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "input",
          label: "户口所在地",
          key: "hu_kou",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "input",
          label: "现家庭住址",
          key: "family_address",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:100%;margin-right:55%'
        },
        {
          type: "inputNumber",
          label: "身高(cm)",
          key: "height",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:45%;'
        },
        {
          type: "radio",
          label: "血型",
          key: "blood_type",
          placeholder: "请选择",
          options:['A型','B型','AB型','O型','其他'],
          rules: [{ required: true, message: "请选择" }],
          style:'width:45%;'
        },
        {
          label:"裸眼视力",
          lablePosition:'left',
          key:'UCVA',
          style:"width:45%;",
          noDefaultLabel:true,
          isRequired:true,
          children:[
            {
              type: "inputNumber",
              label: "左眼",
              key: "UCVA_left",
              placeholder: "请输入",
              rules: { required: true, message: "请输入" },
              style:'width:45%;',
            },
            {
              type: "inputNumber",
              label: "右眼",
              key: "UCVA_right",
              placeholder: "请输入",
              rules: { required: true, message: "请输入" },
              style:'width:45%;',
            },
          ]
        },
        {
          label:"矫正视力",
          lablePosition:'left',
          key:'CVA',
          style:"width:45%;",
          noDefaultLabel:true,
          isRequired:true,
          children:[
            {
              type: "inputNumber",
              label: "左眼",
              key: "CVA_left",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
            {
              type: "inputNumber",
              label: "右眼",
              key: "CVA_right",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
          ]
        },
        {
          type: "select",
          label: "文化程度(最高学历)",
          key: "degree",
          placeholder: "请选择",
          options: resumeData.degrees,
          rules: [{ required: true, message: "请选择" }],
          style:'width:45%;'
        },
        {
          type: "select",
          label: "政治面貌",
          key: "political_status",
          placeholder: "请选择",
          options:politicsStatus,
          rules: [{ required: true, message: "请选择" }],
          style:'width:45%;'
        },
        {
          label:"全日制毕业学校以及专业",
          lablePosition:'left',
          key:'full_time',
          style:"width:100%;",
          noDefaultLabel:true,
          isRequired:true,
          children:[
            {
              type: "input",
              label: "学校",
              key: "full_time_school_name",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
            {
              type: "input",
              label: "专业",
              key: "full_time_major",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
          ]
        },
        {
          label:"最高学历毕业学校以及专业",
          lablePosition:'left',
          key:'ceiling',
          style:"width:100%;",
          noDefaultLabel:true,
          isRequired:true,
          children:[
             {
              type: "input",
              label: "学校",
              key: "ceiling_school_name",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
            {
              type: "input",
              label: "专业",
              key: "ceiling_major",
              placeholder: "请输入",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
          ]
        },
        {
          type: "input",
          label: "特长",
          key: "specialty",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          style:'width:100%;margin-right:55%'
        },
        {
          label:"本人简历 (从高中、中专起填写，包括工作简历）",
          lablePosition:'left',
          key:'my_portfolio',
          style:"width:100%;",
          noDefaultLabel:true,
          isRequired:true,
          plural:true,
          children:[
            {
              type: "select-monthrange",
              label: "起止时间",
              key: "timer",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
            {
              type: "input",
              label: "学习、工作单位及岗位",
              key: "position",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
            {
              type: "input",
              label: "证明人",
              key: "testify",
              rules: [{ required: true, message: "请输入" }],
              style:'width:45%;'
            },
          ]
        },
        {
          label:"主要社会关系",
          lablePosition:'left',
          key:'primary_relationship',
          style:"width:100%;",
          noDefaultLabel:true,
          isRequired:true,
          plural:true,
          children:[
            {
              type: "input",
              label: "关系",
              key: "relationship",
              rules: [{ required: true, message: "请输入" }],
              style:'width:20%;'
            },
            {
              type: "input",
              label: "姓名",
              key: "relationship_name",
              rules: [{ required: true, message: "请输入" }],
              style:'width:30%;'
            },
            {
              type: "input",
              label: "现工作单位",
              key: "work",
              rules: [{ required: true, message: "请输入" }],
              style:'width:40%;'
            },
          ]
        },
        {
          type: "textarea",
          label: "主要工作业绩",
          key: "main_achievements",
          placeholder: "请输入",
          rules: [{ required: true, message: "请输入" }],
          rows: 8,
          maxLength:200,
          style:'width:100%;'
        },
      ],
    },
    uploadLoading:false,
    valid:false,
  }),
  created(){
    let values = JSON.parse(localStorage.getItem('examinationForm'))
    if(values){
      this.formValues = values
    }
  },
  methods:{
    formBtn(){
      this.$refs.forms.onSubmit();
    },
    saveFormValue(valid,values){
      if(valid){
        localStorage.setItem('examinationForm',JSON.stringify(values))
        this.formValues = values
      }
      this.valid = valid
      this.stepState += 1
    },
    submitApply(){
      if(this.valid&&this.formValues.image_url){
        this.postApply({
          "career_fair_id": this.$route.params.career_fair_id,
          "job_id": this.$route.params.job_id,
          "json_data":this.formValues
        })
      }else{
        this.$message.error({
          message:"tiancuola"
        })
      }
    },
    postApply(data){
      this.$axiosRequest({
        method:"POST",
        ids: [this.user_id],
        token:true,
        name: "postExaminationApply",
        data
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
              this.$notify({
                title: "成功",
                message: "提交成功",
                type: "success",
                duration: 1500,
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
                title: "失败",
                message: "请重试",
                type: "error",
                duration: 1500,
              });
        });
    },
    uploadAvatar() {
      document.querySelector("#avatar").click();
    },
    beforeUpload(event) {
      let files = event.target.files;
      let reader = new FileReader();
      if (files) {
        this.uploadLoading = true
        reader.onload = (e) => {
          let result = e.target.result;
          let imageData = {
            image: result
              .replace("data:image/jpeg;base64,", "")
              .replace("data:image/jpg;base64,", "")
              .replace("data:image/png;base64,", ""),
            image_name: "image.jpg",
          };
          this.onUploadFile(imageData);
        };
        if (files.length > 0) reader.readAsDataURL(files[0]);
      }
    },
    onUploadFile(data) {
      this.$axiosRequest({
        method: "POST",
        name: "postUploadFile",
        ids: [this.user_id],
        token: true,
        data,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            console.log("upload", res.data);
            this.uploadLoading = false
            this.$notify({
              title: "成功",
              message: "上传成功",
              type: "success",
              duration: 1500,
            });
            let values = {...this.formValues}
            values.image_url = res.data.image_url
            this.$set(this.formValues,'image_url',res.data.image_url)
            localStorage.setItem('examinationForm',JSON.stringify(values))
          }
        })
        .catch((err) => {
          this.uploadLoading = false;
          this.$notify.error({
            title: "失败",
            message: "上传失败，请重试",
            duration: 1500,
          });
          console.log(err);
        });
    },

  },
  computed:{
    ...mapState({
      user_id:state=>state.user_id
    })
  },
  watch:{

  },
  components:{
    FormGenerator,
  },
}
</script>
<style lang="scss">
// @import "constants/style/public";

  .examination-apply-form{
    padding: 40px 0;
    text-align: left;
    .lable-position-top{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .photo-container{
      margin-top: 30px;
      background-color:#fff;
      .photo{
        width:120px;
        margin:auto;
        text-align:center;
      }
      .photo-img{
        width: 120px; height: 140px;
        margin-bottom:20px;
        border:1px solid var(--borderLineColor,#F4F4F4);
        margin-top:20px;
      }

    }
  } 
</style>